import { template as template_48051389d740423f9e98adb3ee3063bb } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_48051389d740423f9e98adb3ee3063bb(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
