import { template as template_2a241b34e4d7453084fff2e129f3fe55 } from "@ember/template-compiler";
const WelcomeHeader = template_2a241b34e4d7453084fff2e129f3fe55(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
