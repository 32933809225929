import { template as template_8fea20f02ab949168478b50e5d7973dc } from "@ember/template-compiler";
const FKControlMenuContainer = template_8fea20f02ab949168478b50e5d7973dc(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
